/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {useMemo} from 'react';
import {IWidgetSchema, IWidgetSchemaTable} from '../IWidgetProps';
import {Checkbox} from 'primereact/checkbox';
import {useLocale} from '../hooks/useLocale';
import {Button} from 'primereact/button';

type EventTableSettingsProps = {
  columns: IWidgetSchemaTable['columns'];
  isInactive: boolean;
  locales?: IWidgetSchema['locales'];
  setSettingsState: (column: IWidgetSchemaTable['columns']) => void;
  setDefaultColumnConfig: () => void;
};

export const EventTableSettings = ({
  columns,
  isInactive,
  locales,
  setSettingsState,
  setDefaultColumnConfig
}: EventTableSettingsProps) => {
  const {settingsColumnTitle, actions} = useLocale();

  const columnsState = useMemo(() => {
    if (!columns || !locales) return [];
    return Object.entries(columns).map(([key, value]) => ({
      key,
      label: locales.ru[key] || key,
      isHidden: value.permanent ? false : value.hidden || false,
      isDisabled: value.permanent || false
    }));
  }, [columns, locales]);

  return (
    <section
      css={tableSettingsCSS}
      style={{
        opacity: isInactive ? 0.5 : 1,
        pointerEvents: isInactive ? 'none' : 'all'
      }}>
      <header>
        <h3>{settingsColumnTitle}</h3>
        <Button
          className="p-button-secondary p-button-outlined p-button-sm"
          icon="mdi mdi-18px mdi-restore p-c"
          tooltip={actions.byDefault}
          onClick={setDefaultColumnConfig}
        />
      </header>
      <ul css={settingsListCSS}>
        {columnsState.map(({key, label, isHidden, isDisabled}) => (
          <li key={key}>
            <Checkbox
              inputId={`column:${key}`}
              checked={!isHidden}
              disabled={isDisabled}
              onChange={() => setSettingsState({[key]: {...columns[key], hidden: !isHidden}})}
            />
            <label
              htmlFor={`column:${key}`}
              className="ml-2"
              style={{opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'default' : 'pointer'}}>
              {label}
            </label>
          </li>
        ))}
      </ul>
    </section>
  );
};

const tableSettingsCSS = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: calc(30rem / var(--bfs)) calc(30rem / var(--bfs)) 0;

  header {
    display: flex;
    align-items: center;
    margin: 0 0 calc(20rem / var(--bfs)) 0;

    h3 {
      margin: 0 var(--spacer-sm) 0 0;
    }
  }
`;

const settingsListCSS = css`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  max-width: calc(1024rem / var(--bfs));
  padding: 0;
  margin-bottom: calc(30rem / var(--bfs));

  li {
    display: flex:
    align-items: center;
    width: calc(100% / 5 - var(--spacer-xs));
    padding: 0px;
    margin: 0 var(--spacer-xs) var(--spacer) 0;
    position: relative;

    label {
      margin-left: var(--form-label-margin);
    }
  }
`;
