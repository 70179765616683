/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {Fragment, MutableRefObject, useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {IWidgetChild, IWidgetSchema, IWidgetSchemaTable, PublishPayloadData, TableSettingsState} from '../IWidgetProps';
import {history, ISerializable, SearchParams} from '@netvision/lib-history';
import {useWidgetProps} from '../Root';
import {TablePagination} from './TablePagination';
import {EventTableSettings} from './EventTableSettings';
import {Dialog} from 'primereact/dialog';
import {ProgressBar} from 'primereact/progressbar';
import {PageState, PaginatorProps} from 'primereact/paginator';
import {useApiRepository} from '../hooks/useApiRepository';
import {CubeFilter, CubeQuery} from '@netvision/lib-api-repo';
import {useLocale} from '../hooks/useLocale';
import {PublishPayload} from '@netvision/lib-types-frontend';
import {Button} from 'primereact/button';
import {name} from '../../package.json';

type PaginatorPropsExtended = PaginatorProps & PageState;
type EventTableProps = {
  searchParams: ISerializable;
  tableQueryConfig: CubeQuery;
  setSearchParams: React.Dispatch<React.SetStateAction<ISerializable>>;
  setTableQueryConfig: React.Dispatch<Partial<CubeQuery>>;
  selectEvent: (event: Record<string, any>) => void;
  observerID: string;
  eventBusID: string;
};

export const EventTable = ({
  selectEvent,
  tableQueryConfig,
  searchParams,
  setSearchParams,
  setTableQueryConfig,
  observerID,
  eventBusID
}: EventTableProps) => {
  const mountingPointRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const {api} = useApiRepository();
  const {journalTitle, actions} = useLocale();
  const {mountChildren, areas, props} = useWidgetProps();
  const [isExportModalActive, setIsExportModalActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [widgetProps, setWidgetProps] = useState<IWidgetChild>(null!);
  const [tableData, setTableData] = useState<Record<string, unknown>[]>(null!);
  const [tableMetadata, setTableMetadata] = useState<IWidgetSchema>(null!);
  const [filterFields, setFilterFields] = useState<Set<string>>(null!);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableSettingsState, setTableSettingsState] = useReducer(
    (data: TableSettingsState, payload: Partial<TableSettingsState>) => ({...data, ...payload}),
    {isOpened: false, columns: null!, groups: null!, order: null!}
  );
  const [tablePagination, setTablePagination] = useReducer(
    (data: PaginatorPropsExtended, payload: Partial<PaginatorPropsExtended>) => ({...data, ...payload}),
    null!
  );
  const currentWidget = useMemo(
    () => areas.find(({name}) => name === 'table')?.children.find(({src}) => src === '@netvision/widget-react-table'),
    [areas]
  );
  const pagination = useMemo(
    () => ({
      ...tablePagination,
      totalRecords
    }),
    [tablePagination, totalRecords]
  );
  const isFiltersEnabled = useMemo(
    () =>
      searchParams
        ? Object.keys(searchParams).filter((key) => !['offset', 'limit', 'eventId'].includes(key)).length > 0
        : false,
    [searchParams]
  );

  const getTableData = async () => {
    if (!('cubeGetEntities' in api) || api.cubeGetEntities === undefined) {
      throw new Error("cubeGetEntities method isn't implemented");
    }

    const abr = new AbortController();
    const {signal} = abr;
    setIsLoading(true);

    try {
      if (signal.aborted) {
        setIsLoading(false);
        return;
      }
      const {results: events} = await api.cubeGetEntities<Record<string, unknown>>(
        tableQueryConfig,
        props.eventEntityName
      );

      setTableData(events);
      setIsLoading(false);

      const eventsWithStreams = await Promise.all(
        events.map(async (el: any) => {
          if (!('getPreview' in api)) throw new Error("getPreview method doesn't implemented in api");
          const response = await api.getPreview?.({
            id: el.id,
            eventName: props.eventType || 'AssignmentEvent',
            timestamp: new Date(el.timestamp).getTime(),
            signal
          });
          return {id: el.id, blob: response instanceof Blob ? URL.createObjectURL(response) : null};
        })
      );

      const mappedEventsWithStreams = new Map(eventsWithStreams.map(({id, blob}) => [id, blob]));

      setTableData(
        events.map((event) => ({
          ...event,
          preview: mappedEventsWithStreams.get(event.id)
        }))
      );
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getTableMetadata = async () => {
    try {
      const {results} = await api.getEntitiesList<IWidgetSchema>({
        limiter: {
          id: `EntityTypeMetadata:${props.eventType}`,
          type: 'EntityTypeMetadata'
        }
      });
      const metadata = Array.isArray(results) ? results[0] : results;
      setTableMetadata(metadata);
      if (!tableSettingsState.columns) {
        setTableSettingsState({
          columns: metadata.viewEventJournal.columns,
          groups: metadata.viewEventJournal.groups || [],
          order: metadata.viewEventJournal.order || []
        });
        updateTableSettingsCache({
          columns: metadata.viewEventJournal.columns,
          groups: metadata.viewEventJournal.groups || [],
          order: metadata.viewEventJournal.order || []
        });
      } else {
        const actualized = actualizedMetadata(metadata.viewEventJournal, tableSettingsState);
        setTableSettingsState(actualized);
        updateTableSettingsCache({
          columns: actualized.columns,
          groups: actualized.groups,
          order: actualized.order
        });
      }
    } catch (error: unknown) {
      console.error(error);
      return;
    }
  };

  const actualizedMetadata = (remote: IWidgetSchemaTable, local: TableSettingsState) => {
    return {
      isOpened: local.isOpened,
      groups: remote.groups,
      order: remote.order,
      columns: Object.entries(remote.columns).reduce<IWidgetSchemaTable['columns']>((acc, [columnKey, columnValue]) => {
        if (!local.columns[columnKey]) {
          acc[columnKey] = columnValue;
        } else {
          acc[columnKey] = {
            ...columnValue,
            hidden: local.columns[columnKey].hidden ?? false
          };
        }
        return acc;
      }, {})
    };
  };

  const getTableDataCount = async () => {
    if (!('cubeGetQuantity' in api) || api.cubeGetQuantity === undefined) {
      throw new Error("cubeGetQuantity method isn't implemented");
    }

    try {
      const countConfig = {
        ...tableQueryConfig,
        measures: [`${props.eventEntityName}.count`]
      };
      delete countConfig.limit;
      delete countConfig.order;
      const count = (await api.cubeGetQuantity(countConfig, props.eventEntityName)) || 0;
      if (count !== totalRecords) {
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initTableSettingsState = () => {
    const cachedColumnsState = localStorage.getItem(`${name}:table-settings`);
    if (cachedColumnsState) {
      const parsedCachedSettings = JSON.parse(cachedColumnsState);
      setTableSettingsState({...parsedCachedSettings});
    } else {
      getTableMetadata();
    }
  };

  const updateTableSettingsCache = (payload: IWidgetSchemaTable) => {
    const cachedColumnsState = localStorage.getItem(`${name}:table-settings`);
    if (!cachedColumnsState) {
      localStorage.setItem(`${name}:table-settings`, JSON.stringify(payload));
    } else {
      const parsedCachedSettings = JSON.parse(cachedColumnsState);
      localStorage.setItem(
        `${name}:table-settings`,
        JSON.stringify({
          ...parsedCachedSettings,
          ...payload
        })
      );
    }
  };

  const cleanFilterProps = <T extends PublishPayloadData<ISerializable>['payload']>(payload: T | ISerializable) => {
    return Object.entries(payload).reduce<Record<string, any>>((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {});
  };

  const updatePagination = (payload: Partial<PaginatorPropsExtended>, isForcedReload = false) => {
    const payloadKeys = Object.keys(payload) as Array<keyof PaginatorPropsExtended>;
    const isReallyChanged = payloadKeys.some((key) => key in tablePagination && tablePagination[key] !== payload[key]);
    if (isReallyChanged || isForcedReload) setTablePagination(payload);
  };

  const isPositiveInteger = (value: any): value is number =>
    typeof value === 'number' && !isNaN(value) && Number.isInteger(value) && value > 0;

  const getQueryOperator = useCallback((keyParts: string[]): any => {
    const queryKeyDict = new Map([
      ['timestamp', 'inDateRange'],
      ['createdAt', 'inDateRange']
    ]);
    return queryKeyDict.get(keyParts[0]) || 'contains';
  }, []);

  const getQueryValues = useCallback((keyParts: string[], values: unknown) => {
    if (!values) return [];
    switch (keyParts[0]) {
      case 'timestamp':
      case 'createdAt':
        return String(values)
          .split(',')
          .map((val) => new Date(parseInt(val)).toUTCString())
          .flat();
      default:
        return String(values)
          .split(',')
          .map((val) => [
            val,
            val.toUpperCase(),
            val.toLowerCase(),
            val[0]?.toUpperCase() + val.substring(1)?.toLowerCase()
          ])
          .flat();
    }
  }, []);

  const combineQueryFilters = () => {
    const searchParams = SearchParams.parse(history.location.search);
    return Object.entries(searchParams).reduce<CubeFilter[]>((acc, [key, value]) => {
      const keyParts = key.split('.');
      if (filterFields.has(keyParts[0])) {
        acc.push({
          member: tableMetadata.viewEventJournal.columns?.[keyParts[0]]?.filter?.field as string,
          operator: getQueryOperator(keyParts),
          values: getQueryValues(keyParts, value)
        });
      }
      return acc;
    }, []);
  };

  const setTimeFilters = (data: PublishPayloadData<Record<string, {mode: string; values: number[]}>>) => {
    const timePayload = Object.entries(data.payload).reduce<Record<string, string>>((acc, [key, value]) => {
      acc[`${key}.${value.mode}`] = value.values.join(',');
      return acc;
    }, {});
    setSearchParams((prev) => {
      return cleanFilterProps({
        ...prev,
        ...timePayload,
        offset: 0
      });
    });
  };

  const setSimpleFilters = (data: PublishPayloadData<ISerializable>) => {
    setSearchParams((prev) => {
      return cleanFilterProps({
        ...prev,
        ...data.payload,
        offset: 0
      });
    });
  };

  const updateFilter = <T,>(payload: PublishPayload<T>) => {
    // @ts-ignore
    switch (payload.data.sortingField) {
      case 'timestamp':
      case 'createdAt':
      case `${props.eventEntityName}.timestamp`:
      case `${props.eventEntityName}.createdAt`:
        setTimeFilters(payload.data as PublishPayloadData<Record<string, {mode: string; values: number[]}>>);
        break;
      default:
        setSimpleFilters(payload.data as PublishPayloadData<ISerializable>);
    }
    setTablePagination({first: 0});
  };

  const openDetailsPage = <T,>(payload: PublishPayload<T>) => {
    selectEvent(payload.data as Record<string, any>);
  };

  const switchTableSettingsVisibility = () => {
    setTableSettingsState({isOpened: !tableSettingsState.isOpened});
    history.push({
      search: SearchParams.stringify(searchParams),
      hash: !tableSettingsState.isOpened ? '#settings' : ''
    });
  };

  const updateSettingsState = (
    column: IWidgetSchemaTable['columns'],
    groups?: IWidgetSchemaTable['groups'],
    order?: IWidgetSchemaTable['order']
  ) => {
    setTableSettingsState({
      columns: {
        ...tableSettingsState.columns,
        ...column
      },
      groups: groups || tableSettingsState.groups,
      order: order || tableSettingsState.order
    });
  };

  useEffect(() => {
    initTableSettingsState();
    const searchParams = SearchParams.parse(history.location.search);
    if ('eventId' in searchParams) {
      'cubeGetEntities' in api &&
        api.cubeGetEntities &&
        api
          .cubeGetEntities<Record<string, unknown>>(
            {
              ...props.cubeListQuery,
              filters: [
                {
                  member: `${props.eventEntityName}.id`,
                  operator: 'equals',
                  values: [String(searchParams.eventId)]
                }
              ]
            },
            props.eventEntityName
          )
          .then(({results}) => {
            if (results[0]) selectEvent(results[0]);
          })
          .catch(console.error);
    }
    const formattedOffset = isPositiveInteger(searchParams.offset) ? +searchParams.offset : 0;
    const initialPagination = {
      rows: searchParams.limit ? Number(searchParams.limit) : props.cubeListQuery.limit || 10,
      rowsPerPageOptions: [10, 25, 50, 100]
    };
    setSearchParams(searchParams);
    setTablePagination({
      ...initialPagination,
      first: formattedOffset
    });
  }, []);

  useEffect(() => {
    if (tableMetadata) {
      const filterKeys = Object.entries(tableMetadata.viewEventJournal.columns || {}).reduce<Set<string>>(
        (acc, [key, value]) => {
          if ('filter' in value) acc.add(key);
          return acc;
        },
        new Set()
      );
      setFilterFields(filterKeys);
    }
  }, [tableMetadata]);

  useEffect(() => {
    if (filterFields) {
      setTableQueryConfig({
        ...props.cubeListQuery,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        filters: combineQueryFilters(),
        offset: tablePagination.first,
        limit: tablePagination.rows
      });
    }
  }, [filterFields, tablePagination]);

  useEffect(() => {
    if (tableQueryConfig) getTableData();
  }, [tableQueryConfig]);

  useEffect(() => {
    if (searchParams) {
      if (history.location.hash === '#settings') {
        setTableSettingsState({isOpened: true});
      }
      history.push({search: SearchParams.stringify(searchParams), hash: history.location.hash});
    }
  }, [searchParams]);

  useEffect(() => {
    if (!tableData) return;
    if (!currentWidget) throw new Error('Widget is not defined');
    if (!widgetProps) {
      setWidgetProps({
        ...currentWidget,
        props: {
          ...currentWidget.props,
          schema: {
            ...tableMetadata,
            viewEventJournal: {
              ...tableMetadata.viewEventJournal,
              columns: tableSettingsState.columns
            }
          },
          data: tableData
        }
      });
      getTableDataCount();
    } else if (tableData) {
      props.observer.notify(observerID, tableData);
      tablePagination.first === 0 && getTableDataCount();
    }
  }, [tableData, tableSettingsState.columns]);

  useEffect(() => {
    if (!widgetProps) return;
    const {current: mountingPoint} = mountingPointRef;
    if (mountingPoint && currentWidget) {
      const schema = widgetProps.props.schema as Record<string, object>;
      return mountChildren(mountingPoint, [
        {
          ...widgetProps,
          props: {
            ...widgetProps.props,
            schema: {
              ...schema,
              viewTable: schema.viewEventJournal
            },
            observerID,
            eventBusID
          }
        }
      ]);
    }
    return;
  }, [widgetProps]);

  useEffect(() => {
    if (eventBusID) {
      props.eventBus.subscribe(eventBusID, 'filterUpdate', updateFilter);
      props.eventBus.subscribe(eventBusID, 'openDetails', openDetailsPage);
    }

    return () => {
      eventBusID && props.eventBus.unsubscribe(eventBusID, 'filterUpdate');
      eventBusID && props.eventBus.unsubscribe(eventBusID, 'openDetails');
    };
  }, [props.eventBus, eventBusID]);

  useEffect(() => {
    tableSettingsState.columns && !tableMetadata && getTableMetadata();
    if (!widgetProps) return;
    props.eventBus.notify(eventBusID, 'config:changed', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: tableSettingsState.columns
    });
    updateTableSettingsCache({
      columns: tableSettingsState.columns,
      groups: tableSettingsState.groups || []
    });
  }, [tableSettingsState.columns, tableSettingsState.groups]);

  return (
    <div style={{overflow: 'hidden'}}>
      <main css={mainCSS} id="eventTableMain">
        <header css={headerCSS}>
          <div className="inner">
            <h1>
              {journalTitle}
              {tableSettingsState.isOpened && `: ${actions.openSettings.toLowerCase()}`}
            </h1>
            <div className="inner-actions">
              {tableSettingsState.isOpened ? (
                <Button
                  className="p-button-secondary p-button-outlined p-button-sm"
                  icon="mdi mdi-18px mdi-arrow-left p-c"
                  tooltip={actions.back}
                  onClick={switchTableSettingsVisibility}
                />
              ) : (
                <Fragment>
                  <Button
                    className="p-button-secondary p-button-outlined p-button-sm"
                    icon="mdi mdi-18px mdi-cog p-c"
                    tooltip={actions.openSettings}
                    onClick={switchTableSettingsVisibility}
                  />
                  {isFiltersEnabled && (
                    <Button
                      className="p-button-outlined p-button-sm"
                      icon="mdi mdi-18px mdi-filter-remove p-c"
                      tooltip={actions.resetFilters}
                      onClick={() => {
                        setSearchParams(cleanFilterProps({offset: 0, limit: searchParams.limit || 0}));
                        setTimeout(() => updatePagination({first: 0}, true), 0);
                      }}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </header>
        <div css={tabPanelCSS}>
          <ProgressBar mode="indeterminate" style={{height: 'calc(2rem / var(--bfs))', opacity: isLoading ? 1 : 0}} />
          <div
            ref={mountingPointRef}
            css={mountPointCSS}
            className={isLoading ? '--loading' : '--ready'}
            style={{
              visibility: tableSettingsState.isOpened ? 'hidden' : 'visible',
              opacity: isLoading ? 0.5 : 1,
              pointerEvents: isLoading ? 'none' : 'all'
            }}
          />
          {tableSettingsState.isOpened ? (
            <EventTableSettings
              isInactive={isLoading}
              columns={tableSettingsState.columns}
              locales={tableMetadata?.locales}
              setSettingsState={updateSettingsState}
              setDefaultColumnConfig={() =>
                updateSettingsState(
                  tableMetadata.viewEventJournal.columns,
                  tableMetadata.viewEventJournal.groups,
                  tableMetadata.viewEventJournal.order
                )
              }
            />
          ) : (
            <TablePagination
              isInactive={isLoading || pagination.totalRecords === 0}
              pagination={pagination}
              paginationChanged={(props) => {
                updatePagination(props);
                setSearchParams(
                  cleanFilterProps({
                    ...searchParams,
                    offset: props.first,
                    limit: props.rows
                  })
                );
              }}
              refresh={getTableData}
              exportData={() => setIsExportModalActive(true)}
            />
          )}
        </div>
      </main>
      <Dialog
        appendTo={document.getElementById('eventTableMain') || document.body}
        visible={isExportModalActive}
        onHide={() => setIsExportModalActive(false)}
        header={actions.export}
      />
    </div>
  );
};

const mainCSS = css`
  margin-top: calc(94rem / var(--bfs));
  margin-bottom: calc(30rem / var(--bfs));
  height: calc(100vh - 124rem / var(--bfs));
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  .p-dialog-mask:not(.p-dialog-visible) {
    z-index: -1 !important;
  }
`;

const headerCSS = css`
  padding: 0 calc(30rem / var(--bfs));

  .inner {
    position: relative;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      bottom: calc(-2rem / var(--bfs));
      left: 0px;
      height: calc(1rem / var(--bfs));
      width: 100%;
      background: var(--text-color-secondary);
    }

    &-actions {
      margin-left: var(--spacer);
      display: grid;
      gap: var(--spacer-xs);
      grid-auto-flow: column;
    }
  }

  h1 {
    font-size: var(--font-size-h1);
    font-weight: 500;
    margin: 0;
    padding: calc(20rem / var(--bfs)) 0;
    position: relative;

    &:after {
      content: '';
      left: 0px;
      width: 100%;
      z-index: 1;
      position: absolute;
      bottom: calc(-5rem / var(--bfs));
      height: calc(4rem / var(--bfs));
      background: var(--primary-color);
    }
  }
`;

const tabPanelCSS = css`
  padding: 0 calc(30rem / var(--bfs));
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  position: relative;
`;

const mountPointCSS = css`
  margin-top: 2rem;
  font-size: 2rem;
  overflow: hidden;

  .single-spa-parcel-container {
    height: 100%;
    overflow: hidden;

    & > div {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
    }
  }
`;
