/** @jsx jsx */
import {jsx} from '@emotion/react';
import {useRef, useEffect, useMemo, useCallback} from 'react';
import {useWidgetProps} from '../Root';
import {history, ISerializable, SearchParams} from '@netvision/lib-history';

type EventDetailsProps = {
  event: Record<string, any>;
  eventBusID: string;
  onPrev: () => void;
  onNext: () => void;
  onHide: () => void;
  setSearchParams: (params: ISerializable) => void;
};

export const EventDetailsAdapter = ({
  event,
  eventBusID,
  onPrev,
  onNext,
  onHide,
  setSearchParams
}: EventDetailsProps) => {
  const detailsRef = useRef<HTMLDivElement>(null);
  const {mountChildren, areas, props} = useWidgetProps();

  const closeDetailsPage = (searchParams: ISerializable) => {
    delete searchParams.eventId;
    setSearchParams(searchParams);
    onHide();
  };

  useEffect(() => {
    const {current: mountingPoint} = detailsRef;
    const detailsArea = areas.find(({name}) => name === 'details');
    const searchParams = SearchParams.parse(history.location.search);
    if (!detailsArea || !mountingPoint) throw new Error();

    setSearchParams({...searchParams, eventId: event.id});
    return mountChildren(
      mountingPoint,
      detailsArea.children.map((child) => {
        return {
          ...child,
          props: {
            ...child.props,
            event: {...event, timestamp: event.timestamp < 1000000000000 ? event.timestamp * 1000 : event.timestamp},
            eventBusID,
            showActions: true,
            eventName: props.eventType,
            onHide: () => closeDetailsPage(searchParams)
            // onNext,
            // onPrev
          }
        };
      })
    );
  }, []);

  return <div ref={detailsRef} />;
};
