import React, { useRef, useState, useReducer, useCallback, useMemo, useEffect } from 'react'
import { EventTable } from './EventTable';
import { useGetUserInfo } from '../hooks/useGetUserInfo';
import { Toast } from 'primereact/toast';
import { EventDetailsAdapter } from './EventDetailsAdapter';
import { useWidgetProps } from '../Root';
import { ISerializable } from '@netvision/lib-history';
import { useApiRepository } from '../hooks/useApiRepository';
import { CubeQuery } from '@netvision/lib-api-repo';
import { name } from '../../package.json'

export const Main = () => {
  const toastRef = useRef<Toast>(null!);
  const { api } = useApiRepository()
  const [selectedEvent, setSelectedEvent] = useState<null | Record<string, any>>(null)
  // const [nextOrPrevEvent, setNextOrPrevEvent] = useState<null | Record<string, any>>(null)
  const nextOrPrevEvent = useRef<Record<string, any>>(null!);
  const [observerID, setObserverID] = useState<string>(null!)
  const [eventBusID, setEventBusID] = useState<string>(null!)
  const [searchParams, setSearchParams] = useState<ISerializable>(null!)
  const { userId = null } = useGetUserInfo();
  const { props: { eventType, eventEntityName, observer, eventBus } } = useWidgetProps()
  const [tableQueryConfig, setTableQueryConfig] = useReducer(
    (config: CubeQuery, payload: Partial<CubeQuery>): CubeQuery => ({ ...config, ...payload }),
    null!
  )

  const selectEvent = (event: Record<string, any>, canNext = true, canPrev = true) => {
    setSelectedEvent({
      ...event,
      type: eventType,
      canNext,
      canPrev
    })
  }

  const onSlideEvent = useCallback((isNext: boolean) => async () => {
    if (!('cubeGetEntities' in api) || api.cubeGetEntities === undefined) {
      throw new Error('cubeGetEntities method isn\'t implemented')
    }
    if (nextOrPrevEvent.current) {
      setSelectedEvent(nextOrPrevEvent.current)
    }
    // @ts-ignore
    const sortingOrder = tableQueryConfig.order?.[`${eventEntityName}.timestamp`] || 'desc'
    const operator = isNext
      ? sortingOrder === 'desc' ? 'beforeDate' : 'afterDate'
      : sortingOrder === 'desc' ? 'afterDate' : 'beforeDate'

    const { results: events } = await api.cubeGetEntities<Record<string, unknown>>(
      {
        ...tableQueryConfig,
        limit: 2,
        filters: [
          {
            member: `${eventEntityName}.timestamp`,
            operator,
            values: [nextOrPrevEvent.current?.timestamp || selectedEvent?.timestamp]
          },
          ...(tableQueryConfig.filters || [])
        ]
      },
      eventEntityName
    )
    const newNextOrPrevEvent = isNext ? events[1] || null : events[0]
    const newSelectedEvent = isNext ? events[0] : events[1] || events[0] || null
    !nextOrPrevEvent.current && setSelectedEvent({
      ...newSelectedEvent,
      type: eventType,
      canNext: isNext ? typeof events[1] !== 'undefined' : true,
      canPrev: isNext ? true : typeof events[0] !== 'undefined'
    })
    nextOrPrevEvent.current = {
      ...newNextOrPrevEvent,
      type: eventType,
      canNext: isNext ? typeof events[1] !== 'undefined' : true,
      canPrev: isNext ? true : typeof events[0] !== 'undefined'
    }
  }, [selectedEvent])

  const onSlideEventNext = useMemo(() => onSlideEvent(true), [onSlideEvent])
  const onSlideEventPrev = useMemo(() => onSlideEvent(false), [onSlideEvent])

  useEffect(() => {
    setObserverID(observer.addUniqueSubscriberSet())
    setEventBusID(eventBus.addUniqueSubscriberMap())
  }, [])

  useEffect(() => {
    eventBusID && selectedEvent && eventBus.notify(eventBusID, 'event:flipped', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: selectedEvent
    })
  }, [selectedEvent])

  return (
    <div>
      {userId &&
        <EventTable
          searchParams={searchParams}
          tableQueryConfig={tableQueryConfig}
          setSearchParams={(params) => setSearchParams(params)}
          setTableQueryConfig={setTableQueryConfig}
          selectEvent={selectEvent}
          observerID={observerID}
          eventBusID={eventBusID}
        />
      }
      {selectedEvent &&
        <EventDetailsAdapter
          event={selectedEvent}
          eventBusID={eventBusID}
          onNext={onSlideEventNext}
          onPrev={onSlideEventPrev}
          onHide={() => setSelectedEvent(null)}
          setSearchParams={(params) => setSearchParams(params)}
        />
      }
      <Toast ref={toastRef} position="bottom-left" />
    </div>
  )
}
